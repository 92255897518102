import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { useScrollReset } from './hooks/ScrollReset';
import { Toaster } from 'react-hot-toast';

function App() {
  useScrollReset();
  // decide base path from global config
  const content = useRoutes(routes('/setup-account'));
  return (
    <>
      <Toaster />
      {content}
    </>
  );
}

export default App;
