import * as amplitude from '@amplitude/analytics-browser';
import config from '../app/config';

/**
 *
 * Initialize Amplitude Anlaytics
 *
 * @param {*} Object: amplitudeId, partnerId
 *
 */
let apiKey = null;

export const initAmplitude = ({ amplitudeId, partnerId }) => {
  if (process.env.REACT_APP_AMPLITUDE_ID || amplitudeId) {
    apiKey =
      config.BUILD_MODE === 'widget'
        ? amplitudeId
        : process.env.REACT_APP_AMPLITUDE_ID;
    amplitude.init(apiKey, null, {
      partnerId,
      defaultTracking: {
        pageViews: true,
      },
    });
  }
};

// export const setAmplitudeUserDevice = installationToken => {
//   if (apiKey) {
//     amplitude.getInstance().setDeviceId(installationToken);
//   }
// };

// export const setAmplitudeUserId = userId => {
//   if (apiKey) {
//     amplitude.getInstance().setUserId(userId);
//   }
// };

// export const setAmplitudeUserProperties = properties => {
//   if (apiKey) {
//     amplitude.getInstance().setUserProperties(properties);
//   }
// };

export const trackEvent = (eventType, eventProperties) => {
  if (apiKey) {
    amplitude.track(eventType, { ...eventProperties });
  }
};

export { amplitude };
