import React from 'react';
import { useLazyVerifyCouponQuery } from '../../app/services';
import { Button, Input, Label } from 'homebox-ui';

export default function CouponInput({ coupon, setCoupon }) {
  // eslint-disable-next-line no-unused-vars
  const [verifyCoupon, { data, isSuccess, error }] = useLazyVerifyCouponQuery();

  const applyCoupon = async () => {
    if (coupon) await verifyCoupon({ coupon });
  };

  return (
    <>
      <Label htmlFor='coupon'>Promo code (optional)</Label>

      <div className='flex w-full max-w-sm'>
        <Input
          className='w-72 rounded-r-none sm:w-80'
          placeholder='Enter promo code here'
          type='text'
          name='coupon'
          value={coupon}
          id='coupon'
          onChange={e => setCoupon(e.target.value)}
          autoComplete='hb-coupon'
          maxLength='20'
        />
        <Button
          id='apply-coupon'
          disabled={coupon?.length < 1}
          onClick={() => applyCoupon()}
          className='w-full rounded-lg rounded-l-none'
          type='button'
        >
          Apply
        </Button>
      </div>

      <div className='py-1'>
        {isSuccess && (
          <p className='text-typography-success text-xs'>Coupon applied</p>
        )}
        {error?.status === 404 && (
          <p id='coupon-error' className='text-error text-xs'>
            {error?.data?.message || 'Invalid coupon code'}
          </p>
        )}
      </div>
    </>
  );
}
