export const steps = [
  {
    step: 0,
    title: 'Getting Started',
    path: '/',
    fullPage: true,
  },
  {
    step: 1,
    // title: 'Account Setup',
    title: 'Get Started',
    path: '/setup-account',
  },
  {
    step: 2,
    title: 'Bill Setup',
    path: '/setup-bill',
  },
  // {
  //   step: 3,
  //   title: 'Energy Setup',
  //   path: '/energy-setup',
  // },
  {
    step: 3,
    title: 'Account Confirmation',
    path: '/account-setup',
  },
  {
    step: 4,
    title: 'Payment Settings',
    path: '/setup-payment',
  },
  {
    step: 5,
    title: 'Add housemate details',
    path: '/bill-payers',
  },
  {
    step: 6,
    title: 'Setup Mandate',
    path: '/setup-mandate',
  },
  {
    step: 7,
    title: 'Success',
    path: '/success',
    fullPage: true,
    noLayout: true,
  },
  // {
  //   step: 7,
  //   title: 'Setup Rent Recipient',
  //   path: '/setup-rent',
  // },
];
