import { configureStore } from '@reduxjs/toolkit';
import { homeboxService } from './services';

export const store = configureStore({
  reducer: {
    [homeboxService.reducerPath]: homeboxService.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(homeboxService.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
