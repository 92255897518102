import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import DirectDebit from '../assets/direct-debit.png';
import { Button } from 'homebox-ui';
import { MdCheck } from 'react-icons/md';
import MobileNavControl from '../components/MobileNavControl';

export default function SetupMandate() {
  const context = useOutletContext();
  const { orderId, rate, setRate, serviceBill } = context;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextStep = async () => {
    setIsSubmitting(true);

    // set artiicial delay
    await new Promise(resolve => setTimeout(resolve, 800));
    setIsSubmitting(false);

    navigate('/success');
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !orderId) {
      window.location.replace('/');
    }
  }, [orderId]);

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex w-full flex-col space-y-8 bg-white'>
        <div className='mx-auto flex w-full flex-col space-y-8'>
          <div className='flex flex-col space-y-4'>
            <h4 className='text-typography text-h4 leading-10'>
              Direct Debit Mandate Setup
            </h4>
            <p className='text-typography-secondary text-sm font-normal'>
              This is an instruction to your bank or Building society to pay via
              Direct Debit
            </p>
            <div className='text-typography-secondary mt-8 flex flex-col space-y-8 text-sm leading-6'>
              <div className='flex flex-col space-y-3'>
                <span>
                  For information your Direct Debit will be collection using the
                  following details:
                </span>

                <ul className='ml-10 list-disc  text-sm'>
                  <li>Service User Number 171908</li>
                  <li>Service User Name L&Z Re Homebox UK</li>
                </ul>

                <span className='text-typography-secondary text-sm'>
                  Introduction to your Bank or Building Society
                </span>

                <span className='text-typography-secondary text-sm'>
                  Please pay L&Z Re Homebox UK. Direct Debits from the account
                  detailed in this Instruction subject to the safeguards assured
                  by the Direct Debit Guarantee. I understand that this
                  instruction may remain with L&Z Re Homebox UK and, if so,
                  details will be passed electronically to my Bank/Building
                  Society.
                </span>
              </div>
            </div>

            <div className='text-typography-secondary flex flex-col space-y-8  text-sm leading-6'>
              <div className='flex flex-col space-y-3'>
                <span className=' text-lg font-semibold'>
                  The Direct Debit Guarantee
                </span>
                <ul className='ml-10 list-disc space-y-4 text-sm'>
                  <li>
                    This Guarantee is offered by all banks and building
                    societies that accept instructions to pay Direct Debits.
                  </li>
                  <li>
                    If there are any changes to the amount, date or frequency of
                    your Direct Debit L&Z re Homebox UK will notify you 3
                    working days in advance of your account being debited or as
                    otherwise agreed. If you request L&Z re Homebox UK to
                    collect a payment, confirmation of the amount and date will
                    be given to you at the time of the request.
                  </li>
                  <li>
                    If an error is made in the payment of your Direct Debit, by
                    L&Z re Homebox UK or your bank or building society, you are
                    entitled to a full and immediate refund of the amount paid
                    from your bank or building society.
                    <br />- If you receive a refund you are not entitled to, you
                    must pay it back when L&Z re Homebox UK asks you to.
                  </li>
                  <li>
                    You can cancel a Direct Debit at any time by simply
                    contacting your bank or building society. Written
                    confirmation may be required. Please also notify us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-10 hidden items-center justify-center lg:flex'>
            <img
              src={DirectDebit}
              className='h-full max-h-[179px] w-full max-w-[178px]'
              alt='direct debit'
            />
          </div>

          <div className='flex items-center space-x-4'>
            <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
              <Button
                onClick={nextStep}
                type='button'
                id='setup-mandate'
                className='font-semibold'
                size={'cta'}
                loadingText='Submitting..'
                isLoading={isSubmitting}
                endIcon={<MdCheck className='ml-3 h-5 w-5 ' />}
              >
                Confirm
              </Button>
            </div>
          </div>
          <span className='text-sm italic'>
            Once submitted a confirmation email will be immediately sent to you
          </span>
        </div>
      </div>
      <MobileNavControl serviceBill={serviceBill} rate={rate} setRate={setRate}>
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          <div className='mx-auto mt-8 flex w-full max-w-sm flex-col space-y-4'>
            <Button
              onClick={nextStep}
              type='button'
              id='setup-mandate'
              className='font-semibold'
              size={'cta'}
              loadingText='Submitting..'
              isLoading={isSubmitting}
              endIcon={<MdCheck className='ml-3 h-5 w-5 ' />}
            >
              Confirm
            </Button>
          </div>
        </div>
      </MobileNavControl>
    </div>
  );
}
