import React from 'react';
import Logo from '../../assets/homebox.svg';
// import { Logo } from 'homebox-ui';

export default function Header() {
  return (
    <div className='hidden h-[72px] w-full cursor-pointer items-center border-b border-b-neutral-300 bg-white px-8 py-4 sm:flex'>
      <img src={Logo} className='h-14' alt='homebox logo' />
    </div>
  );
}
