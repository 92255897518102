export const nationalities = [
  { value: 'GB', name: 'British' },
  { value: 'AF', name: 'Afghan' },
  { value: 'AL', name: 'Albanian' },
  { value: 'DZ', name: 'Algerian' },
  { value: 'US', name: 'American' },
  { value: 'AD', name: 'Andorran' },
  { value: 'AO', name: 'Angolan' },
  { value: 'AI', name: 'Anguillan' },
  { value: 'AG', name: 'Antiguans' },
  { value: 'AR', name: 'Argentine' },
  { value: 'AM', name: 'Armenian' },
  { value: 'AU', name: 'Australian' },
  { value: 'AT', name: 'Austrian' },
  { value: 'AZ', name: 'Azerbaijani' },
  { value: 'BS', name: 'Bahamian' },
  { value: 'BH', name: 'Bahraini' },
  { value: 'BD', name: 'Bangladeshi' },
  { value: 'BB', name: 'Barbadian' },
  { value: 'AG', name: 'Barbudans' },
  { value: 'BY', name: 'Belarusian' },
  { value: 'BE', name: 'Belgian' },
  { value: 'BZ', name: 'Belizean' },
  { value: 'BJ', name: 'Beninese' },
  { value: 'BM', name: 'Bermudian' },
  { value: 'BT', name: 'Bhutanese' },
  { value: 'BO', name: 'Bolivian' },
  { value: 'BA', name: 'Bosnian' },
  { value: 'BW', name: 'Botswanan' },
  { value: 'BR', name: 'Brazilian' },
  { value: 'VG', name: 'British Virgin Islander' },
  { value: 'BN', name: 'Bruneian' },
  { value: 'BG', name: 'Bulgarian' },
  { value: 'BF', name: 'Burkinabe' },
  { value: 'MM', name: 'Burmese' },
  { value: 'BI', name: 'Burundian' },
  { value: 'KH', name: 'Cambodian' },
  { value: 'CM', name: 'Cameroonian' },
  { value: 'CA', name: 'Canadian' },
  { value: 'CV', name: 'Cape Verdean' },
  { value: 'KY', name: 'Cayman Islander' },
  { value: 'CF', name: 'Central African' },
  { value: 'TD', name: 'Chadian' },
  { value: 'CL', name: 'Chilean' },
  { value: 'CN', name: 'Chinese' },
  { value: 'CO', name: 'Colombian' },
  { value: 'KM', name: 'Comoran' },
  { value: 'CG', name: 'Congolese (Congo)' },
  { value: 'CD', name: 'Congolese (DRC)' },
  { value: 'CK', name: 'Cook Islander' },
  { value: 'CR', name: 'Costa Rican' },
  { value: 'HR', name: 'Croatian' },
  { value: 'CU', name: 'Cuban' },
  { value: 'CY', name: 'Cypriot' },
  { value: 'CZ', name: 'Czech' },
  { value: 'DK', name: 'Danish' },
  { value: 'DJ', name: 'Djiboutian' },
  { value: 'DM', name: 'Dominican' },
  { value: 'NL', name: 'Dutch' },
  { value: 'TL', name: 'East Timorese' },
  { value: 'EC', name: 'Ecuadorean' },
  { value: 'EG', name: 'Egyptian' },
  { value: 'AE', name: 'Emirian' },
  { value: 'GQ', name: 'Equatorial Guinean' },
  { value: 'ER', name: 'Eritrean' },
  { value: 'EE', name: 'Estonian' },
  { value: 'ET', name: 'Ethiopian' },
  { value: 'FO', name: 'Faroese' },
  { value: 'FJ', name: 'Fijian' },
  { value: 'PH', name: 'Filipino' },
  { value: 'FI', name: 'Finnish' },
  { value: 'FR', name: 'French' },
  { value: 'GA', name: 'Gabonese' },
  { value: 'GM', name: 'Gambian' },
  { value: 'GE', name: 'Georgian' },
  { value: 'DE', name: 'German' },
  { value: 'GH', name: 'Ghanaian' },
  { value: 'GI', name: 'Gibraltarian' },
  { value: 'GR', name: 'Greek' },
  { value: 'GL', name: 'Greenlandic' },
  { value: 'GD', name: 'Grenadian' },
  { value: 'GU', name: 'Guamanian' },
  { value: 'GT', name: 'Guatemalan' },
  { value: 'GW', name: 'Guinea-Bissauan' },
  { value: 'GN', name: 'Guinean' },
  { value: 'GY', name: 'Guyanese' },
  { value: 'HT', name: 'Haitian' },
  { value: 'HN', name: 'Honduran' },
  { value: 'HK', name: 'Hong Konger' },
  { value: 'HU', name: 'Hungarian' },
  { value: 'IS', name: 'Icelandic' },
  { value: 'IN', name: 'Indian' },
  { value: 'ID', name: 'Indonesian' },
  { value: 'IR', name: 'Iranian' },
  { value: 'IQ', name: 'Iraqi' },
  { value: 'IE', name: 'Irish' },
  { value: 'IL', name: 'Israeli' },
  { value: 'IT', name: 'Italian' },
  { value: 'CI', name: 'Ivorian' },
  { value: 'JM', name: 'Jamaican' },
  { value: 'JP', name: 'Japanese' },
  { value: 'JO', name: 'Jordanian' },
  { value: 'KZ', name: 'Kazakhstani' },
  { value: 'KE', name: 'Kenyan' },
  { value: 'KN', name: 'Kittian' },
  { value: 'KI', name: 'Kiribati' },
  { value: 'KW', name: 'Kuwaiti' },
  { value: 'KG', name: 'Kyrgyz' },
  { value: 'LA', name: 'Lao' },
  { value: 'LV', name: 'Latvian' },
  { value: 'LB', name: 'Lebanese' },
  { value: 'LR', name: 'Liberian' },
  { value: 'LY', name: 'Libyan' },
  { value: 'LI', name: 'Liechtensteiner' },
  { value: 'LT', name: 'Lithuanian' },
  { value: 'LU', name: 'Luxembourger' },
  { value: 'MO', name: 'Macanese' },
  { value: 'MK', name: 'Macedonian' },
  { value: 'MG', name: 'Malagasy' },
  { value: 'MW', name: 'Malawian' },
  { value: 'MY', name: 'Malaysian' },
  { value: 'MV', name: 'Maldivan' },
  { value: 'ML', name: 'Malian' },
  { value: 'MT', name: 'Maltese' },
  { value: 'MH', name: 'Marshallese' },
  { value: 'MQ', name: 'Martiniquais' },
  { value: 'MR', name: 'Mauritanian' },
  { value: 'MU', name: 'Mauritian' },
  { value: 'MX', name: 'Mexican' },
  { value: 'FM', name: 'Micronesian' },
  { value: 'MD', name: 'Moldovan' },
  { value: 'MC', name: 'Monegasque' },
  { value: 'MN', name: 'Mongolian' },
  { value: 'ME', name: 'Montenegrin' },
  { value: 'MS', name: 'Montserratian' },
  { value: 'MA', name: 'Moroccan' },
  { value: 'LS', name: 'Mosotho' },
  { value: 'MZ', name: 'Mozambican' },
  { value: 'NA', name: 'Namibian' },
  { value: 'NR', name: 'Nauruan' },
  { value: 'NP', name: 'Nepalese' },
  { value: 'NZ', name: 'New Zealander' },
  { value: 'NI', name: 'Nicaraguan' },
  { value: 'NG', name: 'Nigerian' },
  { value: 'NE', name: 'Nigerien' },
  { value: 'KP', name: 'North Korean' },
  // { value: 'GB', name: 'Northern Irish' },
  { value: 'NO', name: 'Norwegian' },
  { value: 'OM', name: 'Omani' },
  { value: 'PK', name: 'Pakistani' },
  { value: 'PW', name: 'Palauan' },
  { value: 'PS', name: 'Palestinian' },
  { value: 'PA', name: 'Panamanian' },
  { value: 'PG', name: 'Papua New Guinean' },
  { value: 'PY', name: 'Paraguayan' },
  { value: 'PE', name: 'Peruvian' },
  { value: 'PL', name: 'Polish' },
  { value: 'PT', name: 'Portuguese' },
  { value: 'PR', name: 'Puerto Rican' },
  { value: 'QA', name: 'Qatari' },
  { value: 'RO', name: 'Romanian' },
  { value: 'RU', name: 'Russian' },
  { value: 'RW', name: 'Rwandan' },
  { value: 'LC', name: 'Saint Lucian' },
  { value: 'SV', name: 'Salvadoran' },
  { value: 'SM', name: 'Sammarinese' },
  { value: 'WS', name: 'Samoan' },
  { value: 'ST', name: 'Sao Tomean' },
  { value: 'SA', name: 'Saudi Arabian' },
  // { value: 'GB', name: 'Scottish' },
  { value: 'SN', name: 'Senegalese' },
  { value: 'RS', name: 'Serbian' },
  { value: 'SC', name: 'Seychellois' },
  { value: 'SL', name: 'Sierra Leonean' },
  { value: 'SG', name: 'Singaporean' },
  { value: 'SK', name: 'Slovak' },
  { value: 'SI', name: 'Slovenian' },
  { value: 'SB', name: 'Solomon Islander' },
  { value: 'SO', name: 'Somali' },
  { value: 'ZA', name: 'South African' },
  { value: 'KR', name: 'South Korean' },
  { value: 'SS', name: 'South Sudanese' },
  { value: 'ES', name: 'Spanish' },
  { value: 'LK', name: 'Sri Lankan' },
  { value: 'SH', name: 'St Helenian' },
  { value: 'LC', name: 'St Lucian' },
  { value: 'SD', name: 'Sudanese' },
  { value: 'SR', name: 'Surinamese' },
  { value: 'SZ', name: 'Swazi' },
  { value: 'SE', name: 'Swedish' },
  { value: 'CH', name: 'Swiss' },
  { value: 'SY', name: 'Syrian' },
  { value: 'TW', name: 'Taiwanese' },
  { value: 'TJ', name: 'Tajik' },
  { value: 'TZ', name: 'Tanzanian' },
  { value: 'TH', name: 'Thai' },
  { value: 'TG', name: 'Togolese' },
  { value: 'TO', name: 'Tongan' },
  { value: 'TT', name: 'Trinidadian or Tobagonian' },
  { value: 'TN', name: 'Tunisian' },
  { value: 'TR', name: 'Turkish' },
  { value: 'TV', name: 'Tuvaluan' },
  { value: 'UG', name: 'Ugandan' },
  { value: 'UA', name: 'Ukrainian' },
  { value: 'UY', name: 'Uruguayan' },
  { value: 'UZ', name: 'Uzbekistani' },
  { value: 'VU', name: 'Vanuatu' },
  { value: 'VE', name: 'Venezuelan' },
  { value: 'VN', name: 'Vietnamese' },
  { value: 'VC', name: 'Vincentian' },
  { value: 'WF', name: 'Wallisian' },
  // { value: 'GB', name: 'Welsh' },
  { value: 'YE', name: 'Yemenite' },
  { value: 'ZM', name: 'Zambian' },
  { value: 'ZW', name: 'Zimbabwean' },
];
