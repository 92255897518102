import React, { useState, useEffect } from 'react';
import {
  Button,
  Label,
  Input,
  RadioGroup,
  RadioGroupItem,
  Checkbox,
  DatePicker,
} from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { PostCodeInput } from '@sunrise/components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { addYears, format } from 'date-fns';
import { useFormikContext, ErrorMessage } from 'formik';
import { useLazyVerifyCouponQuery } from '../app/services';
import { isValidDate, dateFromStartDate } from '../utils';
import CouponInput from '../components/CouponInput';

export default function AccountSetup() {
  const navigate = useNavigate();
  const context = useOutletContext();

  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [verifyCoupon, { data, isSuccess, error }] = useLazyVerifyCouponQuery();

  const {
    postCode,
    setPostCode,
    setFetchQuote,
    quoteDataIsSuccess,
    packageDataIsSuccess,
    setMinEndDate,
    quoteDataLoading,
    isLoading,
    coupon,
    setCoupon,
  } = context;

  const applyCoupon = async () => {
    if (coupon) await verifyCoupon({ coupon });
  };

  const {
    values,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
    setTouched,
    touched,
    handleBlur,
  } = useFormikContext();

  const proceedToNextStep = async () => {
    await applyCoupon();
    const validatedForm = await validateForm();
    setTouched({
      ...touched,
      address: {
        postcode: true,
        line1: true,
        city: true,
        county: true,
      },
      tenancy_end: true,
      tenancy_start: true,
      household_type: true,
      bill_payers: true,
      total_occupants: true,
    });

    setValidated(Object.keys(validatedForm).length === 0);
  };

  useEffect(() => {
    if (validated && (coupon ? isSuccess : true)) {
      // logger.track('fetchNewQuote', {
      //   Journey: 'Tenant Journey',
      // });
      setFetchQuote(`${Math.random() * (99999 - 1) + 1}`);
      // TODO: Uncomment when quote API is fixed
      if (packageDataIsSuccess && quoteDataIsSuccess) {
        navigate('/setup-bill');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, validated, packageDataIsSuccess, quoteDataIsSuccess]);

  return (
    <div className='flex flex-col'>
      <h4 className='text-p-strong sm:text-h4 text-typography-primary'>
        Enter your details below.
      </h4>

      <div className='flex flex-col space-y-6 pt-6'>
        <div className='w-full sm:max-w-xs'>
          {/* <Label htmlFor='confirm-address'>Confirm your address</Label> */}
          <div className='w-full'>
            <PostCodeInput
              defaultPostCode={postCode}
              defaultAddress={values?.address}
              onPostCodeChange={val => {
                setPostCode(val);
                setFieldValue('address.postcode', val);
              }}
              onAddressSelectChange={val => {
                setFieldValue('address', val);
              }}
              customAddress={values?.address}
              setCustomAddress={val => {
                setFieldValue('address', val);
              }}
              handleBlur={handleBlur}
              ignoreStyle
            />
            <span className='flex flex-col space-y-2 text-xs'>
              <ErrorMessage
                component={'span'}
                className='text-error'
                name='address.postcode'
              />
              <ErrorMessage
                component={'span'}
                className='text-error'
                name='address.line1'
              />
              <ErrorMessage
                component={'span'}
                className='text-error'
                name='address.city'
              />
              <ErrorMessage
                component={'span'}
                className='text-error'
                name='address.county'
              />
            </span>
          </div>
        </div>

        <div className='w-full sm:max-w-xs'>
          <Label htmlFor='tenancy_start'>Tenancy Start Date</Label>
          <DatePicker
            label='Tenancy start date'
            date={values?.tenancy_start}
            setDate={val => {
              if (val) {
                setFieldValue('tenancy_start', val);
                setFieldValue('tenancy_end', addYears(new Date(val), 1));
                setMinEndDate(dateFromStartDate(new Date(val), 3));
              }
            }}
            defaultMonth={
              new Date(
                values?.tenancy_start
                  ? values?.tenancy_start?.getFullYear()
                  : new Date().getFullYear(),
                values?.tenancy_start
                  ? values?.tenancy_start?.getMonth()
                  : new Date().getMonth(),
                1
              )
            }
          />
          <Input
            type='date'
            name='tenancy_start'
            value={
              values?.tenancy_start && isValidDate(values?.tenancy_start)
                ? format(new Date(values?.tenancy_start), 'yyyy-MM-dd')
                : ''
            }
            onChange={e => {
              if (e.target.valueAsDate) {
                setFieldValue('tenancy_start', new Date(e.target.valueAsDate));
                setMinEndDate(
                  dateFromStartDate(new Date(e.target.valueAsDate), 3)
                );
              }
            }}
            id='tenancy_start'
            onBlur={e => {
              if (e.target.valueAsDate) {
                setFieldValue('tenancy_start', new Date(e.target.valueAsDate));
              }
              handleBlur(e);
            }}
            className='hidden'
            placeholder='YYYY-MM-DD'
          />
          <ErrorMessage
            component='span'
            className='text-error text-xs'
            name='tenancy_start'
          />
        </div>
        <div className='w-full sm:max-w-xs'>
          <Label htmlFor='tenancy_end'>Tenancy End Date</Label>
          <DatePicker
            label='Tenancy end date'
            date={values?.tenancy_end}
            setDate={val => {
              if (val) {
                setFieldValue('tenancy_end', val);
              }
            }}
            fromDate={
              values?.tenancy_start ? new Date(values.tenancy_start) : null
            }
            defaultMonth={
              new Date(
                values?.tenancy_end
                  ? values?.tenancy_end?.getFullYear()
                  : new Date().getFullYear(),
                values?.tenancy_end
                  ? values?.tenancy_end?.getMonth()
                  : new Date().getMonth(),
                1
              )
            }
          />
          <Input
            type='date'
            name='tenancy_end'
            value={
              isValidDate(values.tenancy_end)
                ? format(new Date(values.tenancy_end), 'yyyy-MM-dd')
                : ''
            }
            onChange={e => {
              if (e.target.valueAsDate) {
                setFieldValue('tenancy_end', new Date(e.target.valueAsDate));
              }
            }}
            id='tenancy_end'
            onBlur={e => {
              if (e.target.valueAsDate) {
                setFieldValue('tenancy_end', new Date(e.target.valueAsDate));
              }
              handleBlur(e);
            }}
            className='hidden'
            placeholder='YYYY-MM-DD'
          />
          <ErrorMessage
            component='span'
            className='text-error text-xs'
            name='tenancy_end'
          />
        </div>

        <div className='flex items-center space-x-2'>
          <Checkbox
            id='move_in_start'
            name='move_in_start'
            onCheckedChange={val => setFieldValue('move_in_start', val)}
            defaultChecked={values.move_in_start}
            checked={values.move_in_start}
          />
          <Label htmlFor='move_in_start'>
            We move in on the tenancy start date
          </Label>
        </div>

        {!values.move_in_start && (
          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='tenancy_move_in'>Move In Date</Label>
            <DatePicker
              label='Move in date'
              date={values.tenancy_move_in}
              setDate={val => {
                if (val) {
                  setFieldValue('tenancy_move_in', val);
                }
              }}
              defaultMonth={
                new Date(
                  values?.tenancy_move_in
                    ? values?.tenancy_move_in?.getFullYear()
                    : new Date().getFullYear(),
                  values?.tenancy_move_in
                    ? values?.tenancy_move_in?.getMonth()
                    : new Date().getMonth(),
                  1
                )
              }
            />
            <Input
              type='date'
              name='tenancy_move_in'
              value={
                isValidDate(values.tenancy_move_in)
                  ? format(new Date(values.tenancy_move_in), 'yyyy-MM-dd')
                  : ''
              }
              onChange={e => {
                if (e.target.valueAsDate) {
                  setFieldValue(
                    'tenancy_move_in',
                    new Date(e.target.valueAsDate)
                  );
                }
              }}
              id='tenancy_move_in'
              className='hidden'
              onBlur={e => {
                if (e.target.valueAsDate) {
                  setFieldValue(
                    'tenancy_move_in',
                    new Date(e.target.valueAsDate)
                  );
                }
                handleBlur(e);
              }}
              placeholder='YYYY-MM-DD'
            />
            <p className='text-typography-primary py-2 text-sm'>
              If this date changes you just need to contact us and let us know
              as soon as possible
            </p>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='tenancy_move_in'
            />
          </div>
        )}
        <div className='w-full sm:max-w-lg'>
          <Label htmlFor='total_occupants'>
            How many people will be living in your new home?
          </Label>
          <RadioGroup
            id='total_occupants'
            name='total_occupants'
            defaultValue={`${values.total_occupants}`}
            value={`${values.total_occupants}`}
            onValueChange={value => {
              setValues({
                ...values,
                total_occupants: Number(value),
                bill_payers: Number(value),
              });
            }}
            className='mt-4 flex flex-wrap'
          >
            {new Array(12).fill('').map((_, idx) => (
              <Label
                htmlFor={`${idx + 1}-housemates`}
                key={`${idx + 1}-housemates`}
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark  [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px] flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus:ring-4 focus-visible:outline-none focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
              >
                <RadioGroupItem
                  value={`${idx + 1}`}
                  id={`${idx + 1}-housemates`}
                  className='sr-only'
                />
                {`${idx + 1}`}
                {idx + 1 === 12 && '+'}
              </Label>
            ))}
          </RadioGroup>
          <ErrorMessage
            component='span'
            className='text-error text-xs'
            name='total_occupants'
          />
        </div>
        {values.total_occupants > 1 && (
          <div className='w-full sm:max-w-lg'>
            <Label htmlFor='bill_payers'>
              How many of these housemates will be paying the bills?
            </Label>
            <RadioGroup
              className='mt-4 flex flex-wrap'
              id='bill_payers'
              name='bill_payers'
              defaultValue={`${values.bill_payers}`}
              value={`${values.bill_payers}`}
              onValueChange={value => {
                setFieldValue('bill_payers', Number(value));
              }}
            >
              {new Array(values.total_occupants).fill('').map((_, idx) => (
                <Label
                  htmlFor={`${idx + 1}-billpayers`}
                  key={`${idx + 1}-billpayers`}
                  className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px]  flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
                >
                  <RadioGroupItem
                    value={`${idx + 1}`}
                    id={`${idx + 1}-billpayers`}
                    className='sr-only'
                  />
                  {`${idx + 1}`}
                  {idx + 1 === 12 && '+'}
                </Label>
              ))}
            </RadioGroup>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='bill_payers'
            />
          </div>
        )}
        <div>
          <Label className='font-sans'>What type of tenant are you?</Label>
          <RadioGroup
            className='mt-2 flex w-full space-x-2 sm:max-w-md'
            defaultValue={values.household_type}
            name='household_type'
            onValueChange={value => {
              setFieldValue('household_type', value);
            }}
            onBlur={handleBlur}
          >
            <Label
              htmlFor='student'
              className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
            >
              <RadioGroupItem
                value='student'
                id='student'
                className='sr-only'
              />
              Student
            </Label>

            <Label
              htmlFor='professional-tenant'
              className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
            >
              <RadioGroupItem
                value='professional-tenant'
                id='professional-tenant'
                className='sr-only'
              />
              Professional Renter
            </Label>
          </RadioGroup>
          <ErrorMessage
            component='span'
            className='text-error text-xs'
            name='household_type'
          />
        </div>
        {/* TODO: Uncomment code once API to update main tenant is flexible */}
        {/* {values.household_type && (
          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='email'>Your Email</Label>
            <Input
              id='email'
              startIcon={<MdMail />}
              placeholder='Enter email address'
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
            />
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='email'
            />
          </div>
        )} */}
        {values.household_type === 'student' && (
          <>
            <div className='w-full sm:max-w-xs'>
              <Label htmlFor='university_name'>Name of your university</Label>

              <Input
                name='university_name'
                id='university_name'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.university_name}
                placeholder='Enter the name of your university'
              />
            </div>
            <div className='w-full sm:max-w-xs'>
              <Label htmlFor='university_team'>
                Are you part of a university team/society?
              </Label>
              <Input
                name='university_team'
                id='university_team'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.university_team}
                placeholder='Enter the name of your team'
              />
            </div>
          </>
        )}
        <div className='w-full sm:max-w-xs'>
          <Label htmlFor='letting_agent'>Name of your letting agent</Label>
          <Input
            name='letting_agent'
            id='letting_agent'
            onChange={handleChange}
            value={values.letting_agent}
            placeholder="Company's Name"
          />
        </div>

        <div className='w-full sm:max-w-xs'>
          <CouponInput coupon={coupon} setCoupon={setCoupon} />
          {error?.status === 404 && (
            <p className='text-error text-xs'>{error?.data?.message}</p>
          )}
        </div>
      </div>
      <div className='mx-auto mt-8 flex w-full max-w-none flex-col space-y-4 sm:max-w-sm'>
        <Button
          variant='primary'
          size={'cta'}
          className='font-semibold'
          onClick={proceedToNextStep}
          disabled={!postCode}
          isLoading={quoteDataLoading || isLoading}
          endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
          type='button'
        >
          Get Quote
        </Button>
      </div>
    </div>
  );
}
