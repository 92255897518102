import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { cn } from '../../utils';
import { MdCleaningServices } from 'react-icons/md';

export default function BillCard(props) {
  const {
    data,
    isBillSelected,
    toggleBillSelection,
    isPerk,
    icon,
    isMonthly = true,
    noOfBillPayers,
  } = props;
  const { bills, name } = data || {};

  const {
    provider,
    prices,
    logo_path,
    id,
    description = '',
  } = bills?.[0] || data;

  const isSelected = isBillSelected(id);

  // Shows weekly price
  const calculateDurationPrice = price => {
    if (!isMonthly) {
      return ((price * 12) / 52).toFixed(2);
    } else {
      return parseFloat(price).toFixed(2);
    }
  };

  const logo = {
    Cleaner: { icon: true, logo: <MdCleaningServices className='h-7 w-7' /> },
  };

  const isWater = name === 'Water';
  const isEnergyProvider = provider === 'Rebel Energy';
  const isCouncilTax = name === 'Council Tax';
  const isTVLicense = name === 'TV Licence';

  let priceOfHousemate = Number(
    prices?.[Number(noOfBillPayers) - 1]?.price_per_housemate
  );
  priceOfHousemate = priceOfHousemate
    ? priceOfHousemate
    : prices?.[0]?.price_per_housemate;

  const BillCardAction = () => {
    return (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <button
              // variant='link'
              className='text-typography-interactive text-p-sm cursor-pointer py-1 font-semibold underline-offset-2 hover:underline sm:p-0 sm:text-base'
            >
              Learn more
            </button>
          </DialogTrigger>
          <DialogContent className=''>
            <DialogHeader>
              <DialogTitle className='text-center'>{name}</DialogTitle>
              <DialogDescription className='py-3'>
                {isWater || isEnergyProvider || isCouncilTax || isTVLicense ? (
                  <>
                    {isEnergyProvider && (
                      <div className='text-typography-secondary text-base'>
                        <p>
                          Rebel Energy are Homebox's fixed energy tariff
                          partner. This energy tariff will be fixed for the next
                          12 months and will only change if your usage is higher
                          or lower than we expect it to be.
                          <span
                            type='button'
                            className='text-typography-interactive ml-2 cursor-pointer underline'
                            onClick={() => {
                              window.open(
                                'https://sandbox.billingbetter.co.uk/popout/rebel',
                                '_blank',
                                'width=500, height=300'
                              );
                            }}
                          >
                            View tariff rates &#38; more info
                          </span>
                        </p>
                      </div>
                    )}
                    {isWater && (
                      <div className='text-typography-secondary text-base'>
                        Your water provider is determined based on the location
                        of your new home and there is no way of getting this
                        bill any cheaper for you unfortunately. The price we
                        display for your water bill here is determined based on
                        an estimate using data from the supplier.
                        <br /> <br />
                        Please note that although we try to be as accurate as
                        possible the actual water price may be slightly less or
                        slightly more once you sign up to Homebox.
                      </div>
                    )}

                    {isCouncilTax && (
                      <div className='text-typography-secondary text-base'>
                        Unless you’re a student, you’ll need to be paying for
                        your council tax. Once you submit your order, we’ll
                        ensure your council tax bill has been set up.
                        <br /> <br />
                        Please note that the price may change once we set up
                        your council tax bill as the price we have displayed is
                        the Band D price for the local authority your home is
                        in.
                      </div>
                    )}
                    {isTVLicense && (
                      <span className='text-typography-secondary text-base'>
                        You need to be covered by a TV Licence to watch or
                        record live television on any channel, or to download or
                        watch any BBC programmes on iPlayer – live, catch up or
                        on demand.
                      </span>
                    )}
                  </>
                ) : (
                  <span className='text-typography-secondary text-base'>
                    {description}
                  </span>
                )}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogTrigger asChild>
                <Button variant={'secondary'} type='button'>
                  Close
                </Button>
              </DialogTrigger>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        {!isPerk && (
          <div className='flex'>
            <div className='flex space-x-3 transition-all'>
              <Button
                onClick={() => {
                  toggleBillSelection(id, bills[0]);
                }}
                variant={isSelected ? 'secondary' : 'primary'}
              >
                {isSelected ? 'Remove' : 'Add Bill'}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className='w-full'>
        <div
          className={cn(
            'border-border bg-background-secondary relative isolate  overflow-hidden rounded-xl border p-4',
            !isSelected && `bg-background-foundation border-border-light`
          )}
        >
          <div className='flex h-full w-full space-x-4'>
            <div className='h-16 w-16'>
              {icon || logo?.[name]?.icon ? (
                <div className='border-border-light text-typography-secondary rounded-3xl border p-3 shadow-lg'>
                  {icon || logo?.[name]?.logo}
                </div>
              ) : (
                <img
                  src={logo_path}
                  alt={`${provider} logo`}
                  className='h-full max-h-[64px] w-full cursor-pointer rounded-2xl object-contain shadow-lg'
                />
              )}
            </div>

            <div
              className={cn(
                'flex w-full flex-col justify-between space-y-4',
                isPerk && 'space-y-1'
              )}
            >
              <div className='flex w-full justify-between'>
                <div className='flex flex-col'>
                  <h4 className='text-p-strong text-typography'>{name}</h4>
                  <p className='text-typography-secondary text-p-tiny sm:text-sm'>
                    Provided by {provider}
                  </p>
                </div>

                <div className='flex flex-col'>
                  <p className='text-p-sm text-typography font-medium sm:text-sm'>
                    £{calculateDurationPrice(Number(priceOfHousemate))}
                    <span className='text-typography-secondary text-sm font-normal'>
                      {isMonthly ? '/month' : '/week'}
                    </span>
                  </p>
                  {!isPerk && (
                    <p className='text-p-tiny text-typography-secondary font-normal sm:text-sm'>
                      per housemate
                    </p>
                  )}
                </div>
              </div>

              <div
                className={cn(
                  'hidden items-end justify-between sm:flex',
                  isPerk && 'hidden sm:flex'
                )}
              >
                <BillCardAction />
              </div>
            </div>
          </div>
          <div className={cn('mt-4 flex items-end justify-between sm:hidden')}>
            <BillCardAction />
          </div>
        </div>
      </div>
    </>
  );
}
