import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'homebox-ui/main.css';
import 'react-phone-number-input/style.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import qs from 'qs';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { GlobalConfigProvider } from './context';
import config from './app/config';
import { initAmplitude } from './utils';

// Configuration Options
// HTMLTarget: {String}, ‘widget’ => the ID of the HTML entity to attach the react app to
// routingType: path, embedded [default=embedded] => should the URL paths be updated?
// journey: tenantPackage, partnerPackage, serviceComparison [default=partnerPackage]
// journeyConfig.serviceType: energy, broadband
// journeyConfig.comparisonStyle: full, quick
// brand: unbranded (no logo), {partnerId}, fromURL [default=unbranded] => where should we find out the brand from?
// partnerId: {partnerId}
// enableABTests: true, false [default=false]

let HBConfig = {
  HTMLTarget: '#root',
  routingType: 'path',
  journey: 'tenantPackage',
  journeyConfig: {
    serviceType: '',
    comparisonStyle: '',
  },
  brand: 'unbranded',
  partnerId: '',
  enableABTests: false,
  amplitudeID: '',
  i8n: config.i8n,
  // userParams: {
  // first_name: 'John',
  // last_name: 'Smith',
  // email: 'john+smith+homebox@gmail.com',
  // address: {
  //   postcode: 'E97TB'
  // },
  // phone_number: {
  //   country_code: '44',
  //   phone_number: '1234567890',
  // },
  // total_occupants: 2,
  // bill_payers: 1,
  // }
};

export const render = args => {
  const paths = {
    // partnerPackage: '/partner-onboarding',
    tenantPackage: '/',
  };

  const routers = {
    embedded: MemoryRouter,
    path: BrowserRouter,
  };

  let selector = '#bb-widget';
  // eslint-disable-next-line no-unused-vars
  const { routingType, journey, amplitudeId, partnerId } = args;

  if (args.HTMLTarget) {
    selector = args.HTMLTarget;
  }

  const initialEntries = ['/']; // defaults as index path
  const search = window?.location?.search;
  // e.g ?first_name=john&last_name=smith&address[postcode]=E97TB&total_occupants=1&bill_payers=4&email=johnsmithhomebox@gmail.com

  let userObj = qs.parse(search?.split('?')?.[1]);

  if (args.userParams || userObj) {
    HBConfig.userParams = {
      ...args.userParams,
      ...userObj,
    };
  }

  if (journey) {
    initialEntries.push(paths[journey]);
  }

  // app analytics
  initAmplitude({ amplitudeId, partnerId });

  // Register experiments
  // emitter.defineVariants('EXP_Button', ['A', 'B']);
  // emitter.defineVariants('EXP_Journey', ['A', 'B']);

  if (args && args.enableABTests !== true) {
    // emitter.setActiveVariant('EXP_Button', 'B');
  }

  if (args && args.enableABTests !== true) {
    // emitter.setActiveVariant('EXP_Journey', 'A');
  }

  if (args && args.i8n !== true) {
    if (typeof window !== 'undefined') {
      window.Weglot = { initialize: () => {} };
    }
  }

  // use router based on config
  const AsRouter = routers[routingType];

  const routerProps = { initialEntries };

  // use last provided path in entries;
  const basePath = initialEntries?.[initialEntries?.length - 1];

  const container = document.querySelector(selector);
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <GlobalConfigProvider config={{ ...HBConfig }}>
          <AsRouter {...routerProps}>
            <App basePath={basePath} />
          </AsRouter>
        </GlobalConfigProvider>
      </Provider>
    </React.StrictMode>
  );
};

if (config.BUILD_MODE !== 'widget') {
  render({ ...HBConfig });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
