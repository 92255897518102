import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const dateFromStartDate = (startDate, months) => {
  return new Date(startDate.setMonth(startDate.getMonth() + months));
};

export const isValidDate = dateString =>
  new Date(dateString).toString() !== 'Invalid Date';

export const snakeCaseToReadable = text => {
  if (!text) return '';
  // Replace underscores with spaces
  const convertedText = text.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const words = convertedText.split(' ');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together
  const readableText = capitalizedWords.join(' ');

  return readableText;
};

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function minDelay(promise, ms) {
  let [p] = await Promise.all([promise, sleep(ms)]);
  return p;
}
