import React, { useState, useEffect } from 'react';
import { Button, Label, Input, RadioGroup, RadioGroupItem } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import FormErrorMessage from '../components/FormErrorMessage';
import { PostCodeInput } from '@sunrise/components';
import { useUpdateTenantOrderMutation } from '../app/services';
import MobileNavControl from '../components/MobileNavControl';

export default function SetupPayment() {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { orderId, rate, setRate, serviceBill } = useOutletContext();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    account_name,
    account_number,
    allow_debit,
    debited_name,
    sort_code,
    billing_address,
    nationality,
    first_name,
    last_name,
    phone_number,
    birthday,
    email,
    welcome_signup_email,
  } = values;

  const data = {
    account_name,
    account_number,
    billing_address,
    birthday,
    email,
    first_name,
    last_name,
    nationality,
    phone_number,
    sort_code,
    orderId,
    welcome_signup_email,
  };

  const [
    updateOrder,
    // eslint-disable-next-line no-unused-vars
    { data: orderData, isLoading, isSuccess, error: orderError },
  ] = useUpdateTenantOrderMutation();

  // const noOfBillPayers = values?.bill_payers;
  // const billSelected = values?.billSelected;

  const setupPaymentSchema = Yup.object().shape({
    account_name: Yup.string().required('account name is required'),
    account_number: Yup.string()
      .min(8, 'invalid account number')
      .max(8, 'invalid account number')
      .required('account number is required'),
    sort_code: Yup.string()
      .min(6, 'invalid sort code')
      .max(8, 'invalid sort code')
      .required('sort code is required'),
    billing_address: Yup.object().shape({
      line1: Yup.string().required('Address Line 1 is required'),
      line2: Yup.string(),
      city: Yup.string().required('City is required.'),
      county: Yup.string(),
      postcode: Yup.string().required('Postcode is required.'),
    }),
  });

  const proceedToNextStep = async () => {
    try {
      setIsSubmitting(true);
      await setupPaymentSchema.validate(
        {
          account_name,
          account_number,
          sort_code,
          billing_address,
        },
        { abortEarly: false }
      );

      // logger.track('setupPayment', {
      //   Journey: 'Tenant Journey',
      // });

      await Promise.all([
        updateOrder({
          ...data,
          birthday: format(birthday, 'dd/MM/yyyy'),
          phone_number: phone_number,
        }),
        new Promise(resolve => setTimeout(resolve, 800)),
      ]);

      setIsSubmitting(false);
      navigate('/bill-payers');
    } catch (error) {
      setIsSubmitting(false);
      const validationErrors = {};
      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });
      setFormErrors(validationErrors);

      let el = document.querySelector('.text-error');
      if (!el) return;

      // Scroll to first known error into view
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (orderError && orderError?.data?.message) {
      // handleErrorDisplay(orderError);
      setIsSubmitting(false);
    }
    if (isSuccess) {
      // toast.success('Tenant Package Created Successfully');
      // logger.track('directDebitMandateSetup', {
      //   'Order ID': orderId,
      //   Journey: 'Tenant Journey',
      // });
      navigate('/bill-payers');
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError, isSuccess]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !orderId) {
      window.location.replace('/');
    }
  }, [orderId]);

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Set up your monthly payment
        </h4>
        <p className='text-typography-secondary mt-1 text-sm font-normal'>
          We collect money from your account each month by direct debit, meaning
          you just need to set up your payment once and we’ll automatically
          charge you each month on the same date.
        </p>

        <p className='text-typography-secondary mt-4 text-sm font-normal'>
          You won’t be charged until all bill payers have set up an account with
          Homebox. Your first payment will then be triggered within two weeks.
          Over the next two weeks our team will start setting up all your bills
          and will be in touch to book a welcome call.
        </p>

        <p className='text-typography-secondary mt-4 text-sm font-normal'>
          If you are going to miss your payment or do not have enough funds in
          your account, please contact our customer services team in advance to
          avoid any late payment fees.
        </p>

        <div className='flex flex-col space-y-6 pt-8'>
          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='account_name'>Account Holder's Name</Label>
            <Input
              name='account_name'
              id='account_name'
              value={values.account_name}
              onChange={handleChange}
              placeholder='Enter name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='account_name' />
          </div>

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='account_number'>Bank Account Number</Label>
            <Input
              name='account_number'
              value={values.account_number}
              onChange={handleChange}
              id='account_number'
              placeholder='Enter account number here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='account_number' />
          </div>

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='sort_code'>Sort code</Label>
            <Input
              name='sort_code'
              value={values.sort_code}
              onChange={handleChange}
              id='sort_code'
              placeholder='Enter code here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name='sort_code' />
          </div>

          <div className='w-full sm:max-w-xs'>
            {/* <Label htmlFor='bank-address'>Bank registered address</Label>
          <Input
            id='bank-address'
            placeholder='Enter address here'
            type='text'
          /> */}
            <PostCodeInput
              defaultPostCode={values?.billing_address?.postcode}
              defaultAddress={values?.billing_address}
              onPostCodeChange={val => {
                setFieldValue('billing_address.postcode', val);
              }}
              onAddressSelectChange={val => {
                setFieldValue('billing_address', val);
              }}
              customAddress={values?.billing_address}
              setCustomAddress={val => {
                setFieldValue('billing_address', val);
              }}
              labelPrefix={'Billing'}
              ignoreStyle
            />
            <span className='flex flex-col space-y-2 text-xs'>
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.postcode'
              />
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.line1'
              />
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.city'
              />
              <FormErrorMessage
                errors={formErrors}
                name='billing_address.county'
              />
            </span>
          </div>

          <fieldset>
            <legend className='text-typography text-sm'>
              The account to be debited is in my name
            </legend>
            <RadioGroup
              defaultValue={values?.debited_name}
              onValueChange={val => setFieldValue('debited_name', val)}
              value={values?.debited_name}
              name='debited_name'
              className='mt-2 flex w-full space-x-2 sm:max-w-sm'
            >
              <Label
                htmlFor='debited-name-yes'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='yes'
                  id='debited-name-yes'
                  className='sr-only'
                />
                Yes
              </Label>

              <Label
                htmlFor='debited-name-no'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='no'
                  id='debited-name-no'
                  className='sr-only'
                />
                No
              </Label>
            </RadioGroup>
            <div className='h-4'>
              {debited_name === 'no' && (
                <span className='text-error text-xs'>
                  Unfortunately, you cannot setup a DD mandate on this account.
                  Please select another payment option
                </span>
              )}
            </div>
          </fieldset>

          <fieldset>
            <legend className='text-typography text-sm'>
              I am the only person required to allow debits on this account
            </legend>
            <RadioGroup
              defaultValue={values?.allow_debit}
              onValueChange={val => setFieldValue('allow_debit', val)}
              value={values?.allow_debit}
              // orientation='horizontal'
              className='mt-2 flex w-full space-x-2 sm:max-w-sm'
              name='allow_debit'
            >
              <Label
                htmlFor='allow-debits-yes'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='yes'
                  id='allow-debits-yes'
                  className='sr-only'
                />
                Yes
              </Label>

              <Label
                htmlFor='allow-debits-no'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='no'
                  id='allow-debits-no'
                  className='sr-only'
                />
                No
              </Label>
            </RadioGroup>
            <div className='h-4'>
              {allow_debit === 'no' && (
                <span className='text-error text-xs'>
                  Unfortunately, you cannot setup a DD mandate on this account.
                  Please select another payment option
                </span>
              )}
            </div>
          </fieldset>
        </div>

        <div className='mt-8 flex flex-col space-y-3'>
          {/* <div className='flex items-center space-x-2'>
          <Checkbox id='dd-terms' />
          <label
            className='text-base font-medium leading-none text-typography peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
            htmlFor='dd-terms'
          >
            I have read and agree to the{' '}
            <span className='text-primary underline underline-offset-2'>
              Direct Debit Guarantee
            </span>
          </label>
        </div> */}

          {/* <div className='flex items-center space-x-2'>
          <Checkbox id='terms' />
          <label
            className='text-base font-medium leading-none text-typography peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
            htmlFor='terms'
          >
            I agree to the Homebox Pay{' '}
            <span className='text-primary underline underline-offset-2'>
              terms of service
            </span>
            .
          </label>
        </div> */}
        </div>
        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            isLoading={isSubmitting || isLoading}
            onClick={proceedToNextStep}
          >
            Set up bill payers
          </Button>
        </div>
      </div>

      <MobileNavControl serviceBill={serviceBill} rate={rate} setRate={setRate}>
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            isLoading={isSubmitting || isLoading}
            onClick={proceedToNextStep}
          >
            Set up bill payers
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
