import * as React from 'react';
import { format } from 'date-fns';
import { cn } from 'src/utils/helpers';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { MdCalendarToday } from 'react-icons/md';

type DatePickerProps = {
  date: Date | undefined;
  label?: string;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

export function DatePicker({
  date,
  setDate,
  label,
  ...props
}: DatePickerProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className='w-full'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant={'secondary'}
            onClick={() => setOpen(true)}
            className={cn(
              'w-full min-w-[280px] justify-start rounded-lg px-3 py-2 text-left font-normal sm:px-3',
              !date && 'text-neutral-400'
            )}
          >
            <>
              <MdCalendarToday className='mr-2 h-4 w-4' />
              {date ? (
                <span className='text-typography-primary font-medium'>
                  {' '}
                  {format(date, 'PPP')}{' '}
                </span>
              ) : (
                <span>{label || 'Pick a date'}</span>
              )}
            </>
          </Button>
        </PopoverTrigger>
        <PopoverContent className='border-border-light w-auto rounded-lg border bg-white p-0 shadow-lg'>
          <Calendar
            mode='single'
            selected={date}
            onSelect={val => {
              setDate(val);
              setOpen(false);
            }}
            initialFocus
            {...props}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default DatePicker;
