import React, { useEffect, Fragment } from 'react';
import { Button, Tabs, TabsList, TabsTrigger } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import BillCard from '../components/BillCard';
import GroupBillCard from '../components/GroupBillCard';
import BillsLoadingSkeleton from '../components/Skeletons/BillsLoadingSkeleton';
import MobileNavControl from '../components/MobileNavControl';

export default function SetupBill() {
  const { rate, setRate, serviceBill } = useOutletContext();

  const isMonthly = rate === 'monthly';

  const context = useOutletContext();
  const { values, setFieldValue, setValues, isSubmitting } = useFormikContext();

  const housemates = values?.total_occupants;
  const noOfBillPayers = values?.bill_payers;
  // select a bill
  const selectBill = payload => {
    setFieldValue('billSelected', [...values?.billSelected, payload]);
  };

  // remove bill from selections
  const deselectBill = payload => {
    let allBills = values?.billSelected;
    let idx = allBills?.findIndex(bill => bill?.id === payload);

    if (idx !== -1) {
      allBills.splice(idx, 1);
    }
    setFieldValue('billSelected', [...allBills]);
  };

  const isBillSelected = id => {
    let allSelected = values?.billSelected;

    for (let i = 0; i < allSelected.length; i++) {
      if (allSelected?.[i]?.id === id) {
        return true;
      }
    }
    return false;
  };

  const getSelectedBillFromGroup = groupId => {
    let allSelected = values?.billSelected;

    for (let i = 0; i < allSelected.length; i++) {
      if (allSelected?.[i]?.group_id === groupId) {
        return allSelected[i];
      }
    }
    return false;
  };

  const toggleBillSelection = (id, payload) => {
    if (isBillSelected(id)) {
      deselectBill(id);
    } else {
      selectBill(payload);
    }
  };

  const energyBillSelected = payload => {
    setFieldValue('energy_bill_selected', payload);
  };

  const { essentials, extras, perks, quoteDataLoading, isLoading } = context;

  const autoSelectEssentials = () => {
    const autoSelectedBills = [];
    const add_ons =
      values?.billSelected.length > 0
        ? values?.billSelected.map(x => x?.id)
        : [];
    if (essentials?.length > 0 && values?.billSelected.length < 1) {
      essentials?.forEach(payload => {
        // refactor: Figure a way to pass bill reference as it's much cleaner
        let isEnergy = payload?.bills?.[0]?.group_name === 'Renewable Energy';
        if (!isEnergy && !add_ons.includes(payload?.bills?.[0]?.id)) {
          autoSelectedBills.push(payload?.bills?.[0]);
        }
      });
    }

    // if(perks.length > 0 && values?.billSelected.length < 1){
    //   perks?.forEach(payload => {
    //     if (!add_ons.includes(payload?.id)) {
    //       autoSelectedBills.push(payload);
    //     }
    //   });
    // }
    setValues({
      ...values,
      billSelected: [...values?.billSelected, ...autoSelectedBills],
    });
  };
  useEffect(() => {
    autoSelectEssentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [essentials]);

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography-primary'>
          Create your bills package
        </h4>
        <p className='text-typography-secondary mt-1 text-sm font-normal'>
          Get your free bills quote by choosing from the list of bills below.
          The amounts shown are based on {housemates} housemate
          {Number(housemates) > 1 && 's'} living at this property.
        </p>

        <Tabs
          defaultValue={rate}
          onValueChange={value => setRate(value)}
          value={rate}
          className='hidden w-full flex-col px-16 py-8 sm:flex'
        >
          <TabsList>
            <TabsTrigger className='w-full' value='monthly'>
              Monthly
            </TabsTrigger>
            <TabsTrigger className='w-full' value='weekly'>
              Weekly
            </TabsTrigger>
          </TabsList>
        </Tabs>

        {/* Move Content Divider */}
        <div className='bg-main-linear-3 relative mt-8 pt-[2px]'>
          <div className='text-typography-primary bg-neutral-100 px-6 py-2 text-lg normal-case sm:mt-0 sm:uppercase'>
            Essentials
          </div>
        </div>

        <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
          {!isLoading && !quoteDataLoading && essentials?.length > 0 ? (
            essentials?.map((bill, idx) => (
              <Fragment key={`${bill?.name}-${idx}`}>
                {bill?.bills?.length === 1 ? (
                  <BillCard
                    data={bill}
                    billSelected={values?.billSelected}
                    toggleBillSelection={toggleBillSelection}
                    isBillSelected={isBillSelected}
                    isMonthly={isMonthly}
                    noOfBillPayers={noOfBillPayers}
                  />
                ) : (
                  <GroupBillCard
                    data={bill}
                    billSelected={values?.billSelected}
                    toggleBillSelection={toggleBillSelection}
                    isBillSelected={isBillSelected}
                    isMonthly={isMonthly}
                    getSelectedBillFromGroup={getSelectedBillFromGroup}
                    noOfBillPayers={noOfBillPayers}
                    energyBillSelected={energyBillSelected}
                  />
                )}
              </Fragment>
            ))
          ) : (
            <BillsLoadingSkeleton />
          )}
        </div>
        {extras?.length > 0 && (
          <>
            <div className='bg-main-linear-2 relative mt-8 pt-[2px]'>
              <div className='text-typography-primary  bg-neutral-100 px-6 py-2 text-lg uppercase'>
                Extras
              </div>
            </div>
            <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
              {!isLoading && !quoteDataLoading ? (
                extras?.length > 0 &&
                extras?.map((bill, idx) => (
                  <Fragment key={`${idx}-${bill?.name}`}>
                    {bill?.bills?.length === 1 ? (
                      <BillCard
                        data={bill}
                        billSelected={values?.billSelected}
                        toggleBillSelection={toggleBillSelection}
                        isBillSelected={isBillSelected}
                        isMonthly={isMonthly}
                        noOfBillPayers={noOfBillPayers}
                      />
                    ) : (
                      <GroupBillCard
                        data={bill}
                        billSelected={values?.billSelected}
                        toggleBillSelection={toggleBillSelection}
                        isBillSelected={isBillSelected}
                        isMonthly={isMonthly}
                        getSelectedBillFromGroup={getSelectedBillFromGroup}
                        noOfBillPayers={noOfBillPayers}
                      />
                    )}
                  </Fragment>
                ))
              ) : (
                <BillsLoadingSkeleton />
              )}
            </div>
          </>
        )}
        {/* perks */}
        <div className='bg-main-linear-4 relative mt-8 pt-[2px]'>
          <div className='text-typography-primary  bg-neutral-100 px-6 py-2 text-lg uppercase'>
            Perks
          </div>
        </div>
        <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
          {!isLoading && !quoteDataLoading ? (
            perks?.length > 0 &&
            perks?.map((bill, idx) => (
              <BillCard
                key={`${bill?.name}-${idx}`}
                data={bill}
                billSelected={values?.billSelected}
                toggleBillSelection={toggleBillSelection}
                isBillSelected={isBillSelected}
                isMonthly={isMonthly}
                isPerk
              />
            ))
          ) : (
            <BillsLoadingSkeleton />
          )}
        </div>
        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            isLoading={isSubmitting}
            className='font-semibold'
            type='submit'
            id='submit-addons'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
          >
            Continue
          </Button>
        </div>
      </div>
      <MobileNavControl serviceBill={serviceBill} rate={rate} setRate={setRate}>
        <div className='mx-auto flex w-full max-w-sm flex-col space-y-4'>
          <Button
            variant='primary'
            size={'cta'}
            isLoading={isSubmitting}
            className='font-semibold'
            type='submit'
            id='submit-addons'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
          >
            Continue
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
