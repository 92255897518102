import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Testimonial from '../components/Testimonial';
import QuotePanel from '../components/QuotePanel';
import Stepper from '../components/Stepper';
import { cn, steps } from '../utils';
import AccountSummaryPanel from '../components/AccountSummaryPanel';

export default function OnboardingLayout({ children, layoutData }) {
  const location = useLocation();
  const { rate, setRate, serviceBill } = layoutData;
  const activeStep = () => {
    let active = steps.filter(step => step.path === location.pathname);
    return active?.[0];
  };

  const activePage = activeStep();
  return (
    <>
      <Header />
      {/* fullPage and noLayout set to true for pages that doesn't benefit from the onboarding layout styles */}
      <div
        className={cn(
          !activePage?.noLayout &&
            `min-h-[calc(100vh - 72px)] bg-neutral-50 p-0 sm:p-6 lg:p-8 2xl:min-h-screen`
        )}
      >
        <div
          className={cn(
            !activePage?.noLayout &&
              `flex h-full w-full flex-col lg:flex-row lg:justify-between 2xl:mx-auto 2xl:max-w-[92rem]`
          )}
        >
          <div
            className={cn(!activePage?.fullPage && 'mx-auto w-full lg:w-7/12')}
          >
            <div
              className={cn(
                !activePage?.fullPage && 'flex w-full flex-col bg-white'
              )}
            >
              {!activePage?.fullPage && <Stepper />}
              <div className={cn(!activePage?.fullPage && `p-6 sm:p-8`)}>
                {children}
              </div>
            </div>
          </div>
          <div
            className={cn('relative w-5/12', activePage?.fullPage && `hidden`)}
          >
            <div className='sticky bottom-2 top-2 hidden w-full lg:flex'>
              {activePage.step === 1 ? (
                <div className='flex w-full flex-col space-y-8'>
                  <Testimonial />
                </div>
              ) : (
                <div className='w-full px-8'>
                  <>
                    {activePage.path === '/setup-mandate' ? (
                      <AccountSummaryPanel />
                    ) : (
                      <QuotePanel
                        step={activePage.step}
                        rate={rate}
                        setRate={setRate}
                        serviceBill={serviceBill}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
