import React, { useEffect, useState } from 'react';
import { Button } from 'homebox-ui';
import { MdArrowForward, MdSkipNext } from 'react-icons/md';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import BillPayerCard from '../components/BillPayerCard';
import AddBillPayer from '../components/BillPayerCard/AddBillPayer';
import MobileNavControl from '../components/MobileNavControl';

export default function BillPayers() {
  const { orderId, setRate, rate, serviceBill } = useOutletContext();
  const { values, setFieldValue } = useFormikContext();
  const billPayers = values?.tenants;
  const navigate = useNavigate();
  const noOfBillPayers = values?.bill_payers;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const proceedNextStep = async () => {
    setIsSubmitting(true);
    // logger.track('inviteBillPayers', {
    //   'Number Of Tenants': noOfBillPayers,
    //   Journey: 'Tenant Journey',
    // });
    // artificial delay
    await new Promise(resolve => setTimeout(resolve, 800));
    navigate('/setup-mandate');
    setIsSubmitting(false);
  };

  const deleteBillPayer = idx => {
    const updatedBillPayers = [...billPayers];
    updatedBillPayers.splice(idx, 1); // Remove the user at the specified index

    setFieldValue('tenants', updatedBillPayers);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !orderId) {
      window.location.replace('/');
    }
  }, [orderId]);

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Set up bill payers
        </h4>
        <p className='mt-1 text-sm font-normal text-neutral-600'>
          Invite the other bill payers to your account; you are Bill payer 1.
        </p>

        {/* Show AddNewBillPayer component if all bill payers have not been added */}
        {noOfBillPayers > 1 && billPayers.length !== noOfBillPayers && (
          <AddBillPayer billPayers={billPayers} setFieldValue={setFieldValue} />
        )}

        <div className='text-typography-secondary mt-8 rounded-lg bg-neutral-100 px-6 py-2 text-lg uppercase'>
          Bill Payers
        </div>
        {billPayers.length > 0 && (
          <BillPayerCard data={billPayers[0]} payerNo={0} mainPayer />
        )}

        {billPayers?.length > 1 &&
          billPayers?.slice(1).map((payer, idx) => (
            <div key={idx} className='col-span-6 lg:col-span-5'>
              <BillPayerCard
                deleteBillPayer={deleteBillPayer}
                data={payer}
                payerNo={idx + 1}
              />
            </div>
          ))}

        <div className='mx-auto mt-16 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          {noOfBillPayers > 1 && billPayers.length !== noOfBillPayers ? (
            <Button
              variant='tertiary'
              size={'cta'}
              className='mt-4 font-semibold'
              endIcon={<MdSkipNext className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              I’ll finish this later
            </Button>
          ) : (
            <Button
              variant='primary'
              size={'cta'}
              className='font-semibold'
              endIcon={<MdArrowForward className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
      <MobileNavControl serviceBill={serviceBill} rate={rate} setRate={setRate}>
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          {noOfBillPayers > 1 && billPayers.length !== noOfBillPayers ? (
            <Button
              variant='secondary'
              size={'cta'}
              className='mt-4 font-semibold'
              endIcon={<MdSkipNext className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              I’ll finish this later
            </Button>
          ) : (
            <Button
              variant='primary'
              size={'cta'}
              className='font-semibold'
              endIcon={<MdArrowForward className='ml-3 h-5 w-5' />}
              isLoading={isSubmitting}
              onClick={proceedNextStep}
            >
              Continue
            </Button>
          )}
        </div>
      </MobileNavControl>
    </div>
  );
}
