import React, { useState, useEffect } from 'react';
import { useAddBillPayerToTenantOrderMutation } from '../../app/services';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import FormErrorMessage from '../../components/FormErrorMessage';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Button, Label, Input } from 'homebox-ui';
import { MdAdd } from 'react-icons/md';

export default function AddBillPayer({
  billPayers,
  setFieldValue,
  validateOnSubmit = () => {},
}) {
  const { orderId } = useOutletContext();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState('');

  const [newBillPayer, setNewBillPayer] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
  });

  const handleFieldChange = name => e => {
    setNewBillPayer({
      ...newBillPayer,
      [name]: e.target.value,
    });
  };

  const [
    addBillPayerToOrder,
    // eslint-disable-next-line no-unused-vars
    { data: billPayerData, isSuccess, error: billPayerError },
  ] = useAddBillPayerToTenantOrderMutation();

  const inviteMateSchema = Yup.object().shape({
    first_name: Yup.string().required('first name is required.'),
    last_name: Yup.string().required('last name is required.'),
    phone_number: Yup.string().required('phone number is required'),
  });

  const submitBillPayer = async () => {
    try {
      setIsSubmitting(true);
      await inviteMateSchema.validate(
        {
          ...newBillPayer,
        },
        { abortEarly: false }
      );
      // don't submit for partner orders
      if (orderId) {
        setIsSubmitting(true);
        await addBillPayerToOrder({
          ...newBillPayer,
          orderId,
        });
        setFieldValue('tenants', [...billPayers, newBillPayer]);
      } else {
        // toast.success('Added Bill Payer Successfully');
        setIsSubmitting(false);
      }
      setNewBillPayer({
        first_name: '',
        last_name: '',
        phone_number: '',
      });

      validateOnSubmit(true);
      setIsSubmitting(false);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });
      setFormErrors(validationErrors);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (billPayerError) {
      //   handleErrorDisplay(billPayerError);
      setIsSubmitting(false);
    }
    if (isSuccess) {
      // toast.success('Added Bill Payer Successfully');
      setIsSubmitting(false);
    }
  }, [billPayerError, isSuccess]);

  return (
    <>
      <div className='flex flex-col space-y-6 pt-8'>
        <div className='flex w-full max-w-md space-x-2'>
          <div className='w-full'>
            <Label htmlFor='first-name'>First Name</Label>
            <Input
              value={newBillPayer?.first_name}
              onChange={handleFieldChange('first_name')}
              id='first-name'
              placeholder='Enter name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name={`first_name`} />
          </div>
          <div className='w-full'>
            <Label htmlFor='last-name'>Last Name</Label>
            <Input
              value={newBillPayer?.last_name}
              onChange={handleFieldChange('last_name')}
              id='last-name'
              placeholder='Enter name here'
              type='text'
            />
            <FormErrorMessage errors={formErrors} name={`last_name`} />
          </div>
        </div>
        <div>
          <div className='flex items-center space-x-4'>
            <div className='flex w-full max-w-md flex-col'>
              <PhoneInput
                defaultCountry='GB'
                placeholder='e.g. 7700 900123'
                value={newBillPayer?.phone_number}
                onChange={val => {
                  handleFieldChange('phone_number')({ target: { value: val } });
                }}
                id='mobile-number'
              />
              {/* TODO: Confirm the possiblility of adding emails
               <Label htmlFor='email'>Email or Mobile Number</Label>
               <Input id='email' placeholder='Enter details here' type='text' /> 
               */}
            </div>

            <Button
              variant='primary'
              className='hidden lg:flex'
              isLoading={isSubmitting}
              onClick={submitBillPayer}
            >
              Add
            </Button>
          </div>
          {newBillPayer?.phone_number && newBillPayer?.phone_number !== '' && (
            <span className='text-error mt-1 block text-xs'>
              {newBillPayer?.phone_number
                ? isValidPhoneNumber(newBillPayer?.phone_number)
                  ? undefined
                  : 'Invalid phone number'
                : 'Phone number required'}
            </span>
          )}
          <FormErrorMessage errors={formErrors} name={`phone_number`} />
        </div>
        <Button
          className='flex lg:hidden'
          variant='primary'
          startIcon={<MdAdd />}
          isLoading={isSubmitting}
          onClick={submitBillPayer}
        >
          Add bill payer
        </Button>
      </div>
    </>
  );
}
