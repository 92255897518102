import { Button } from 'homebox-ui';
import React from 'react';
import { MdArrowDropUp } from 'react-icons/md';
import { QuotePanelContent } from '../QuotePanel';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { steps } from '../../utils';
import { AccountSummaryContent } from '../AccountSummaryPanel';

export default function MobileNavControl({
  serviceBill,
  rate,
  setRate,
  children,
}) {
  const location = useLocation();
  const activeStep = () => {
    let active = steps.filter(step => step.path === location.pathname);
    return active?.[0];
  };
  const activePage = activeStep();

  const { values } = useFormikContext();
  const quoteData = values?.billSelected ?? [];
  const noOfBillPayers = values?.bill_payers;
  const isMonthly = rate === 'monthly';

  const calculateDurationPrice = price => {
    if (!isMonthly) {
      return ((Number(price) * 12) / 52).toFixed(2);
    } else {
      return parseFloat(price).toFixed(2);
    }
  };

  // total price paid by an housemate
  const pricePerHousemate =
    quoteData?.length > 0
      ? quoteData
          ?.map(x => {
            let priceOfHousemate = Number(
              x?.prices?.[Number(noOfBillPayers) - 1]?.price_per_housemate
            );
            return priceOfHousemate
              ? priceOfHousemate
              : Number(x?.prices?.[0]?.price_per_housemate);
          })
          .reduce(
            (previousService, currentService) =>
              Number(previousService) + Number(currentService),
            0
          )
      : 1.0; // 1.00 represents HB Service Fee
  return (
    <Dialog>
      <DialogContent className='bottom-4 h-[85vh] max-w-xl overflow-y-scroll bg-transparent p-3 shadow-transparent sm:bottom-auto sm:h-auto sm:bg-white'>
        <div className='rounded-xl bg-white px-2 py-6 sm:px-0 sm:py-0 '>
          <DialogHeader>
            <DialogTitle className='h-10 text-center'>Summary</DialogTitle>
            <DialogDescription className='flex max-h-screen flex-col space-y-2 overflow-y-scroll p-2 lg:max-h-[75vh] lg:p-6'>
              {activePage.path === '/setup-mandate' ? (
                <AccountSummaryContent />
              ) : (
                <QuotePanelContent
                  serviceBill={serviceBill}
                  setRate={setRate}
                  rate={rate}
                  step={4}
                />
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className='mt-6'>
            <DialogTrigger asChild>
              <Button>Close</Button>
            </DialogTrigger>
          </DialogFooter>
        </div>
      </DialogContent>
      <div className='shadow-lgUp fixed bottom-0 left-0 flex min-h-[140px] w-full rounded-t-xl bg-white lg:hidden'>
        <div className='absolute -top-6 left-[50%] -translate-x-[50%] rounded-full bg-white p-2'>
          <DialogTrigger asChild>
            <Button variant={'secondary'} size={'icon'}>
              <MdArrowDropUp className='h-6 w-6' />
            </Button>
          </DialogTrigger>
        </div>
        <div className=' w-full px-6 pb-12 pt-4'>
          <div className='flex justify-between'>
            <div className='flex flex-col justify-end space-y-2 text-base lg:max-w-[200px]'>
              <p className='font-semibold '>Total</p>
            </div>

            <div className='flex flex-col space-y-2 '>
              <p className='text-base'>
                <span className='text-typography font-medium'>
                  {' '}
                  £
                  {calculateDurationPrice(
                    Number(pricePerHousemate) +
                      Number(serviceBill?.charge_per_housemate || 0)
                  )}
                </span>
                {isMonthly ? '/month' : '/week'}
              </p>
              <p className='text-sm font-normal'>per bill payer</p>
            </div>
          </div>
          <div className='mt-5 w-full'>
            {/* <Button className='w-full'>Continue</Button> */}
            {children}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
