import { Badge, Button } from 'homebox-ui';
import React from 'react';
import { MdDelete, MdPerson } from 'react-icons/md';

export default function BillPayerCard({
  data,
  mainPayer,
  deleteBillPayer,
  payerNo,
}) {
  const { first_name, last_name, phone_number } = data;
  return (
    <div className=' flex items-center justify-between py-4'>
      <div className='flex items-center space-x-4'>
        <div className='border-border-light text-typography-secondary rounded-full border p-3 shadow-lg'>
          <MdPerson className=' h-7 w-7' />
        </div>

        <div className='flex flex-col'>
          <div className='flex items-center space-x-2'>
            <p className='text-base font-semibold'>
              {first_name} {last_name}
            </p>
            {mainPayer && <Badge variant='secondary'>YOU</Badge>}
          </div>
          <p>{phone_number}</p>
        </div>
      </div>

      <Button
        size='icon'
        variant='secondary'
        disabled={mainPayer}
        onClick={() => deleteBillPayer(payerNo)}
        className='text-typography-secondary disabled: rounded-3xl p-3'
      >
        <MdDelete className='h-7 w-7' />
      </Button>
    </div>
  );
}
