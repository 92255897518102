import React, { useState } from 'react';
// import { Button } from 'homebox-ui';
import { MdCheck, MdStar, MdStarOutline } from 'react-icons/md';

export default function Success() {
  const [star, setStar] = useState(null);

  const goToTrustPilot = () => {};
  return (
    <>
      <div className='onboarding-bg screen-without-header flex items-center justify-center bg-cover px-4 py-6 lg:px-10 lg:py-16'>
        <div className='flex h-full w-full items-center'>
          <div className='mx-auto w-full max-w-4xl'>
            <div className='mt-6 flex w-full flex-col bg-[hsl(var(--success-foreground))]/50 p-8 backdrop-blur'>
              <div className='w-full bg-white '>
                <div className='flex flex-col items-center space-y-4 p-8'>
                  <div className='border-border-light text-typography-secondary rounded-full border p-3 shadow-lg'>
                    <MdCheck className=' h-7 w-7' />
                  </div>

                  <h4 className=' text-h4 text-typography'>Congratulations!</h4>
                  <p className=' text-typography-secondary text-s'>
                    Awesome, you’ve done the hard work to get your bills set up.
                    Before proceeding to complete your bills setup, we’ll need
                    all your housemates to set up their Homebox accounts.
                  </p>
                  <p className=' text-typography-secondary text-s'>
                    <strong>When will my first monthly payment be?</strong> Once
                    we have set up all your bills, we’ll send you an email and
                    text confirming your first monthly payment date. We normally
                    aim to charge at the start of the month in line with your
                    rent payments.
                  </p>

                  {/* <Button
                    size={'cta'}
                    variant='primary'
                    endIcon={<MdArrowForward className='ml-3 h-6 w-6' />}
                    type='button'
                  >
                    Continue to Log in
                  </Button> */}

                  <p className=' text-typography-secondary text-m my-2 font-bold'>
                    How was your experience today?
                  </p>
                  <div className='flex space-x-1'>
                    {new Array(5).fill('').map((n, idx) => (
                      <a
                        href='https://www.trustpilot.com/evaluate/billingbetter.co.uk'
                        target='_blank'
                        rel='noreferrer noopener'
                        className='cursor-pointer'
                        onMouseEnter={() => setStar(idx)}
                        onMouseLeave={() => setStar(null)}
                        onClick={goToTrustPilot}
                      >
                        {star && idx <= star ? (
                          <MdStar className='h-12 w-12 fill-[#F59E0C]' />
                        ) : (
                          <MdStarOutline className='h-12 w-12 fill-[#F59E0C]' />
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
