import React, { useState } from 'react';
import {
  Button,
  Label,
  Input,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  ScrollArea,
  DatePicker,
} from 'homebox-ui';
import { MdMail, MdArrowForward } from 'react-icons/md';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { format, subYears } from 'date-fns';
import { isValidDate, nationalities } from '../utils';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import FormErrorMessage from '../components/FormErrorMessage';
import MobileNavControl from '../components/MobileNavControl';

export default function AccountConfirmation() {
  const navigate = useNavigate();
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { rate, setRate, serviceBill } = useOutletContext();

  const {
    first_name,
    last_name,
    phone_number,
    birthday,
    email,
    terms_of_service,
    confirm_contract,
    agree_pricing,
    data_permission,
  } = values;

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let maxDob = new Date();
  maxDob = new Date(maxDob.setFullYear(maxDob.getFullYear() - 18)).setMonth(12);

  const accountSetupSchema = Yup.object().shape({
    email: Yup.string().email().required('email is required'),
    first_name: Yup.string().required('first name is required'),
    last_name: Yup.string().required('last name is required'),
    terms_of_service: Yup.boolean()
      .required('The terms of service must be accepted.')
      .oneOf([true], 'The terms of service must be accepted.'),
    confirm_contract: Yup.boolean()
      .required('Kindly Confirm the terms listed by checking the box above.')
      .oneOf(
        [true],
        'Kindly Confirm the terms listed by checking the box above.'
      ),
    agree_pricing: Yup.boolean()
      .required('Kindly Confirm the terms listed by checking the box above.')
      .oneOf(
        [true],
        'Kindly Confirm the terms listed by checking the box above.'
      ),
    data_permission: Yup.boolean()
      .required('Kindly Confirm the terms listed by checking the box above.')
      .oneOf(
        [true],
        'Kindly Confirm the terms listed by checking the box above.'
      ),
    birthday: Yup.date()
      .nullable()
      .typeError('Invalid birthday Date')
      .max(new Date(maxDob), 'you have to be older than 18.')
      .required('birthday is required.'),
    phone_number: Yup.string().required('phone number is required'),
  });

  const addBillPayerToTenantOrder = async () => {
    try {
      setIsLoading(true);
      await accountSetupSchema.validate(
        {
          email,
          first_name,
          last_name,
          birthday,
          terms_of_service,
          confirm_contract,
          agree_pricing,
          data_permission,
          phone_number,
        },
        { abortEarly: false }
      );

      setFieldValue('tenants.0', { first_name, last_name, phone_number });
      await new Promise(resolve => setTimeout(resolve, 800));
      // logger.track('setupAccount', {
      //   Journey: 'Tenant Journey',
      // });
      setIsLoading(false);
      navigate('/setup-payment');
    } catch (error) {
      setIsLoading(false);
      const validationErrors = {};
      error.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      });
      setFormErrors(validationErrors);

      let el = document.querySelector('.text-error');
      if (!el) return;

      // Scroll to first known error into view
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // const noOfBillPayers = values?.bill_payers;
  // const billSelected = values?.billSelected;
  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Confirm your account details
        </h4>
        <div className='flex flex-col space-y-6 pt-8'>
          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='first_name'>First Name</Label>
            <Input
              name='first_name'
              value={values.first_name}
              onChange={handleChange}
              id='first_name'
              placeholder='Enter first name'
              type='text'
            />
            <FormErrorMessage name='first_name' errors={formErrors} />
          </div>

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='last_name'>Last Name</Label>
            <Input
              name='last_name'
              value={values.last_name}
              onChange={handleChange}
              id='last_name'
              placeholder='Enter last name'
              type='text'
            />
            <FormErrorMessage name='last_name' errors={formErrors} />
          </div>
          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='phone_number'>Your Mobile Number</Label>
            <PhoneInput
              defaultCountry='GB'
              placeholder='e.g. 7700 900123'
              value={values.phone_number}
              onChange={val => {
                setFieldValue('phone_number', val);
              }}
              id='phone_number'
            />
            {values.phone_number && (
              <span className='text-error text-xs'>
                {values.phone_number
                  ? isValidPhoneNumber(values.phone_number)
                    ? undefined
                    : 'Invalid phone number'
                  : 'Phone number required'}
              </span>
            )}
            <FormErrorMessage name='phone_number' errors={formErrors} />
          </div>

          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='email'>Email</Label>
            <Input
              id='email'
              startIcon={<MdMail />}
              placeholder='Enter email address'
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
            />
            <FormErrorMessage name='email' errors={formErrors} />
          </div>

          <div className='w-full  sm:max-w-xs'>
            <Label htmlFor='dob'>Date of Birth</Label>
            {/* Refactor: Make year component select field <div className='flex space-x-1'>
            <Input placeholder='Day' maxLength={2} type='text' />
            <Input placeholder='Month' maxLength={2} type='text' />
            <Input placeholder='Year' maxLength={2} type='text' />
          </div> */}
            <DatePicker
              label={'Choose birthday'}
              date={values.birthday}
              setDate={val => {
                if (val) {
                  setFieldValue('birthday', val);
                }
              }}
              fromDate={subYears(new Date(), 100)}
              toDate={maxDob}
              captionLayout='dropdown'
              defaultMonth={
                new Date(
                  ((values?.birthday && values?.birthday?.getFullYear()) ||
                    1990) ??
                    maxDob.getFullYear(),
                  ((values?.birthday && values?.birthday?.getMonth()) || 0) ??
                    maxDob.getMonth(),
                  1
                )
              }
              required
            />
            <Input
              type='date'
              name='birthday'
              value={
                isValidDate(values.birthday)
                  ? format(new Date(values.birthday), 'yyyy-MM-dd')
                  : ''
              }
              onChange={e => {
                if (e.target.value) {
                  setFieldValue('birthday', new Date(e.target.value));
                }
              }}
              onBlur={e => {
                if (e.target.valueAsDate) {
                  setFieldValue('birthday', new Date(e.target.valueAsDate));
                }
              }}
              className='hidden'
              placeholder='YYYY-MM-DD'
              id='birthday'
            />
            <FormErrorMessage name='birthday' errors={formErrors} />
          </div>
          {values?.household_type === 'student' && (
            <div className='w-full  sm:max-w-xs'>
              <Label htmlFor='university_year'>Your University Year</Label>
              <Select
                value={values?.university_year || ''}
                onValueChange={value => {
                  setFieldValue('university_year', value);
                }}
                name='university_year'
              >
                <SelectTrigger className=''>
                  <SelectValue placeholder='Your University Year' />
                </SelectTrigger>
                <SelectContent id='university_year'>
                  <SelectGroup>
                    <ScrollArea className='h-[240px] w-full rounded-md'>
                      <SelectItem disabled value=''>
                        Choose
                      </SelectItem>
                      {Array(4)
                        .fill('')
                        .map((n, idx) => (
                          <SelectItem key={`${idx + 1}`} value={`${idx + 1}`}>
                            Year {idx + 1}
                          </SelectItem>
                        ))}
                      <SelectItem value='5'>Year 5+</SelectItem>
                    </ScrollArea>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormErrorMessage name='university_year' errors={formErrors} />
            </div>
          )}

          <div className='w-full sm:max-w-xs'>
            <Label htmlFor='nationality'>Nationality</Label>
            <Select
              value={values.nationality}
              onValueChange={value => {
                setFieldValue('nationality', value);
              }}
              name='nationality'
            >
              <SelectTrigger className=''>
                <SelectValue placeholder='Nationality' />
              </SelectTrigger>
              <SelectContent id='nationality'>
                <SelectGroup>
                  <ScrollArea className='h-[400px] w-full rounded-md'>
                    <SelectItem disabled value=''>
                      Select Nationality
                    </SelectItem>
                    {nationalities.map(({ name, value }, idx) => (
                      <SelectItem key={`${idx}-${name}`} value={value}>
                        {name}
                      </SelectItem>
                    ))}
                  </ScrollArea>
                </SelectGroup>
              </SelectContent>
            </Select>
            <FormErrorMessage name='nationality' errors={formErrors} />
          </div>
        </div>

        <div className='mt-8 flex flex-col space-y-5'>
          <div className='flex space-x-2'>
            <Checkbox id='marketing_news' />
            <div className='flex flex-col space-y-1'>
              <Label
                className='text-typography text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                htmlFor='marketing_news'
              >
                I want to receive special offers from our partners, marketing
                messages & our newsletter from Homebox.
              </Label>
              <span className=' text-sm text-neutral-600'>
                I can also opt out of these at any time in my account settings
                or via the link in the email.
              </span>
            </div>
          </div>

          <div className='flex items-center space-x-2'>
            <Checkbox id='terms' />
            <label
              className='text-typography text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              htmlFor='terms'
            >
              Yes, I want Homebox to be able to contact me through my phone.
            </label>
          </div>
          <div>
            <div className='flex h-5 items-center space-x-2'>
              <Checkbox
                id='terms_of_service'
                name='terms_of_service'
                defaultChecked={values?.terms_of_service}
                checked={values?.terms_of_service}
                onCheckedChange={val => setFieldValue('terms_of_service', val)}
              />

              <Label
                htmlFor='terms_of_service'
                className='text-typography cursor-pointer text-base font-medium'
              >
                I have read and agree to the
                <a
                  href='https://www.billingbetter.co.uk/terms-of-service'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='ml-2 underline'
                >
                  Terms of Service
                </a>
              </Label>
            </div>
            <FormErrorMessage name='terms_of_service' errors={formErrors} />
          </div>
          <div>
            <div className='flex items-start '>
              <div className='flex h-5 items-center'>
                <Checkbox
                  id='confirm_contract'
                  name='confirm_contract'
                  defaultChecked={values?.confirm_contract}
                  checked={values?.confirm_contract}
                  onCheckedChange={val =>
                    setFieldValue('confirm_contract', val)
                  }
                />
              </div>
              <div className='ml-2 flex flex-col space-y-4 text-base font-medium'>
                <Label
                  htmlFor='confirm_contract'
                  className='text-typography text-base'
                >
                  I understand that Homebox will not take over any existing
                  contracts I have set up for my property - for example,
                  internet/TV packages.
                </Label>
              </div>
            </div>
            <FormErrorMessage name='confirm_contract' errors={formErrors} />
          </div>

          <div>
            <div className='flex items-start'>
              <div className='flex h-5 items-center'>
                <Checkbox
                  id='agree_pricing'
                  name='agree_pricing'
                  defaultChecked={values?.agree_pricing}
                  checked={values?.agree_pricing}
                  onCheckedChange={val => setFieldValue('agree_pricing', val)}
                />
              </div>
              <div className='ml-2 flex flex-col space-y-2 text-base font-medium'>
                <Label
                  htmlFor='agree_pricing'
                  className='text-typography text-base'
                >
                  I understand and agree to the below:
                </Label>
                <span className='text-typography-secondary text-sm'>
                  The price for your council tax is based upon the number of
                  tenants living in your property against the price for the
                  council tax in the area of your property.
                </span>
                <span className='text-typography-secondary text-sm'>
                  Councils updated their prices at the end of April each year,
                  your price will then be
                </span>
                <span className='text-typography-secondary text-sm'>
                  In order to set up your council tax, we need to receive a copy
                  of the bill from yourselves or the council. If you receive a
                  bill in the post from the council, please upload it to your
                  online account.
                </span>
              </div>
            </div>
            <FormErrorMessage name='agree_pricing' errors={formErrors} />
          </div>

          <div>
            <div className='flex items-start'>
              <div className='flex h-5 items-center'>
                <Checkbox
                  id='data_permission'
                  name='data_permission'
                  defaultChecked={values?.data_permission}
                  checked={values?.data_permission}
                  onCheckedChange={val => setFieldValue('data_permission', val)}
                />
              </div>
              <div className='ml-4 text-sm'>
                <Label
                  htmlFor='data_permission'
                  className='text-typography cursor-pointer text-base'
                >
                  I confirm that I have the permission of my housemates to share
                  their details with Homebox in order to set up the account with
                  Homebox.
                </Label>
              </div>
            </div>
            <FormErrorMessage name='data_permission' errors={formErrors} />
          </div>
        </div>
        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            isLoading={isLoading}
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            onClick={addBillPayerToTenantOrder}
          >
            Set Payment details
          </Button>
        </div>
      </div>

      <MobileNavControl serviceBill={serviceBill} rate={rate} setRate={setRate}>
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            isLoading={isLoading}
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            onClick={addBillPayerToTenantOrder}
          >
            Set Payment details
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
