import React, { useEffect, useRef, useState } from 'react';
import OnboardingLayout from '../layouts/OnboardingLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useGlobalConfig } from '../context';
import {
  useCreatePartnerOrderMutation,
  useGetCustomQuoteQuery,
  useGetTenantPackageByPostcodeQuery,
} from '../app/services';
import { dateFromStartDate } from '../utils';
import { toast } from 'react-hot-toast';
import { AlertWarning } from '../components/AlertCard';

export default function CustomerOnboarding() {
  const [rate, setRate] = React.useState('monthly');
  const { config } = useGlobalConfig();
  const userPostCode = config?.userParams?.address?.postcode;
  let threeMonthsFromToday = dateFromStartDate(new Date(), 3);

  let [postCode, setPostCode] = useState(userPostCode || '');
  let [housemates, setHousemates] = useState(1);
  let [isStudent, setIsStudent] = useState(false);
  let [addressId, setAddressId] = useState(null);
  let [coupon, setCoupon] = useState('');
  const [minEndDate, setMinEndDate] = useState(threeMonthsFromToday);
  const navigate = useNavigate();

  let formikRef = useRef(null);
  const [fetchQuote, setFetchQuote] = useState(null);

  useEffect(() => {
    if (formikRef?.values && Object.entries(formikRef.values).length > 0) {
      setHousemates(formikRef?.values?.total_occupants);
      setAddressId(formikRef?.values?.address?.external_id);
      let householdType = formikRef?.values?.household_type === 'student';
      setIsStudent(householdType);
    }
  }, [formikRef, fetchQuote]);

  const {
    data: quoteData,
    isLoading: quoteDataLoading,
    isSuccess: quoteDataIsSuccess,
    error: quoteDataError,
  } = useGetCustomQuoteQuery(
    {
      postcode: postCode,
      housemates,
      address_id: addressId,
      is_student: isStudent,
    },
    { skip: !(postCode && housemates && addressId && fetchQuote) }
  );

  const {
    data: customPackageData,
    isLoading,
    isSuccess: packageDataIsSuccess,
    error: packageError,
  } = useGetTenantPackageByPostcodeQuery(
    {
      postcode: postCode,
      housemates,
      is_student: isStudent,
    },
    { skip: !(postCode && housemates && fetchQuote) }
  );

  const customPackage =
    customPackageData?.packages?.[customPackageData?.packages?.length - 1] ||
    {};

  const [
    createOrder,
    // eslint-disable-next-line no-unused-vars
    { data: orderData, isSuccess: orderSuccess, error: orderError },
  ] = useCreatePartnerOrderMutation();

  const essentialBills =
    quoteData?.data?.length > 0
      ? quoteData?.data?.filter(p => p?.is_essential).map(p => p?.id)
      : [];

  const serviceBill = quoteData?.service_charge
    ? {
        total_charge: quoteData?.service_charge,
        charge_per_housemate: quoteData?.service_charge_per_housemate,
      }
    : {};
  const processGroupBills = data => {
    if (data?.length < 1) return [];
    let addons = data;
    let result = [];

    do {
      let bills = [];
      let currentGroupId = addons?.[0]?.group_id;
      let currentGroupName = addons?.[0]?.title;

      addons?.length > 0 &&
        addons?.forEach(bill => {
          if (currentGroupId === bill?.group_id) {
            bills.push({ ...bill, group_name: currentGroupName });
          }
        });
      let is_essential = essentialBills.includes(currentGroupId);
      result?.push({
        bills,
        id: currentGroupId,
        name: currentGroupName,
        is_essential,
      });
      addons = addons?.filter(bill => bill.group_id !== currentGroupId); // shorten array as loop through
    } while (addons?.length);

    return result.sort((a, b) => a?.id - b?.id); // sort group_id in order
  };

  const customBills =
    customPackage?.addons?.length > 0
      ? processGroupBills(customPackage?.addons)
      : [];

  const essentials = customBills.filter(q => q.is_essential) || [];
  const extras = customBills?.filter(q => !q.is_essential) || [];
  const perks =
    quoteData?.data?.length > 0
      ? quoteData?.data?.filter(p => p?.reference === 'perks')?.[0]?.bills
      : [];

  const orderId = orderData?.data?.order_id;
  // Note: the above is the "partner ID" that's stored in the 'partner_orders' table and
  // not the household id that is often used elsewhere.
  // The household ID can be acceed via orderData?.data?.tenancy?.id;

  const layoutData = {
    serviceBill,
    setRate,
    rate,
  };

  const sharedContext = {
    extras,
    postCode,
    setPostCode,
    essentials,
    orderId,
    setFetchQuote,
    quoteDataLoading,
    quoteDataError,
    quoteDataIsSuccess,
    packageDataIsSuccess,
    isLoading,
    setMinEndDate,
    coupon,
    setCoupon,
    perks,
    setRate,
    rate,
    serviceBill,
  };

  const tenantOrderSchema = Yup.object().shape({
    tenancy_start: Yup.date().required('The tenancy start date is required.'),
    tenancy_end: Yup.date()
      .min(
        minEndDate,
        `The tenancy end must be a date after ${format(
          minEndDate,
          'dd-MM-yyyy'
        )}.`
      )
      .required('The tenancy end date is required.'),
    household_type: Yup.string().required('Household type is required.'),
    address: Yup.object().shape({
      line1: Yup.string().required('Address Line 1 is required'),
      line2: Yup.string(),
      city: Yup.string().required('City is required.'),
      county: Yup.string(),
      postcode: Yup.string().required('Postcode is required.'),
    }),
    total_occupants: Yup.number().required('Number of tenants is required.'),
    bill_payers: Yup.number().required('Number of bill payers is required.'),
  });

  useEffect(() => {
    if (orderError) {
      // handleErrorDisplay(orderError);
    }
    if (orderSuccess) {
      navigate('/account-setup');
      // logger.track('newOrderCreated', {
      //   'Order ID': orderId,
      //   'Package ID': 6,
      //   Journey: 'Tenant Journey',
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError, orderSuccess, orderId]);

  useEffect(() => {
    if (packageError) {
      // handleErrorDisplay(packageError);
    }
  }, [packageError]);
  return (
    <>
      <Formik
        innerRef={el => (formikRef = el)}
        validateOnBlur
        validateOnChange={false}
        initialValues={{
          package_id: 6,
          billSelected: [],
          address: {
            line1: '',
            line2: '',
            city: '',
            county: '',
            postcode: '',
          },
          reference: 'BILLING1',
          total_occupants: '',
          bill_payers: '',
          household_type: '',
          move_in_start: true,
          tenancy_move_in: '',
          tenancy_start: '',
          tenancy_end: '',
          tenancy_type: '',
          tenants: [],
          account_name: '',
          account_number: '',
          billing_address: {
            line1: '',
            line2: '',
            city: '',
            county: '',
            postcode: '',
          },
          birthday: '',
          email: '',
          first_name: '',
          last_name: '',
          nationality: 'GB',
          phone_number: '',
          sort_code: '',
          university_name: '',
          university_team: '',
          university_year: '',
          allow_debit: '',
          debited_name: '',
          letting_agent: '',
          terms_of_service: false,
          confirm_contract: false,
          agree_pricing: false,
          data_permission: false,
          welcome_signup_email: true,
          energy_bill_selected: false,
          // new fields for rent users
          letting_landlord: '',
          recipient_account_number: '',
          letting_agent_sort_code: '',
          rent_payment_reference: '',
          ...config.userParams,
        }}
        validationSchema={tenantOrderSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (values?.energy_bill_selected) {
            try {
              const add_ons =
                values?.billSelected.length > 0
                  ? values?.billSelected.map(x => x?.id)
                  : [];
              const tenancy_start = format(values.tenancy_start, 'dd/MM/yy');
              const tenancy_end = format(values.tenancy_end, 'dd/MM/yy');
              const tenancy_move_in = values.move_in_start
                ? tenancy_start
                : format(values?.tenancy_move_in, 'dd/MM/yy');

              await createOrder({
                ...values,
                address: {
                  line1: values?.address?.line1,
                  line2: values?.address?.line2,
                  city: values?.address?.city,
                  county: values?.address?.county,
                  postcode: values?.address?.postcode || postCode,
                },
                add_ons,
                tenancy_move_in,
                tenancy_start,
                tenancy_end,
                coupon,
              });
            } catch (err) {
              // toast.error(`${err.message}`);
            }
          } else {
            setSubmitting(false);
            toast.custom(t => (
              <AlertWarning
                title={'Energy plan must be selected'}
                currentToast={t}
              />
            ));
          }
        }}
      >
        {({ handleSubmit }) => (
          <OnboardingLayout layoutData={layoutData}>
            <form onSubmit={handleSubmit}>
              <Outlet
                context={{
                  ...sharedContext,
                }}
              />
            </form>
          </OnboardingLayout>
        )}
      </Formik>
    </>
  );
}
